import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  Input,
  Modal,
  Typography,
} from "@mui/material";
import Select from "react-select";

import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BpCheckbox from "../../../components/Form/CheckBox";
import { useLoader } from "../../../hooks/useLoader";
import { returnErrors } from "../../../store/reducers/error";
import {
  applyCouponCode,
  getChallengeOptions,
  paymentGateway,
} from "../../../store/reducers/payment";
import { dollarUS } from "../../../utilities/formatter";

import axios from "axios";
import {
  STRIPE_CANCEL_URL,
  STRIPE_SUCCESS_URL,
} from "../../../constants/payments";
import useMobile from "../../../hooks/useMobile";
import { baseUrl } from "../../../utilities/apis/apis";

const summaryStyle = {
  background: "rgba(66, 66, 109, 0.3)",
  borderRadius: "24px",
  padding: "1.5rem",
  "& label, h5, div": {
    // flexBasis: "48%",
  },
};

const summaryItemCtn = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const titleStyle = {
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "140%",
  letterSpacing: "0.004em",
  color: "#FFFFFF",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  // height: '90vh',
  display: "flex",
  flexDirection: "column",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TnCContent = () => {
  const isMobile = useMobile();
  const language = useSelector(
    (state) => state.settings.account_settings.language
  );

  return language === "en" ? (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: 0,
        paddingTop: isMobile ? "129.4118%" : "75vh",
        paddingBottom: 0,
        boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
        overflow: "hidden",
        borderRadius: "8px",
        willChange: "transform",
      }}
    >
      <iframe
        title="lazy"
        loading="lazy"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          border: "none",
          padding: 0,
          margin: 0,
        }}
        src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFmodTgDQo&#x2F;view?embed"
        allowfullscreen="allowfullscreen"
        allow="fullscreen"
      ></iframe>
    </div>
  ) : (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: 0,
        paddingTop: isMobile ? "129.4118%" : "75vh",
        paddingBottom: 0,
        boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)",
        marginTop: "1.6em",
        marginBottom: "0.9em",
        overflow: "hidden",
        borderRadius: "8px",
        willChange: "transform",
      }}
    >
      <iframe
        title="tnc"
        loading="lazy"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          border: "none",
          padding: 0,
          margin: 0,
        }}
        src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFmodTgDQo&#x2F;view?embed"
        allowfullscreen="allowfullscreen"
        allow="fullscreen"
      ></iframe>
    </div>
  );
};

const PaymentForm = ({ paymentMethod, coinMethod }) => {
  const [accBalance, setAccBalance] = useState("");
  const [planType, setPlanType] = useState("");
  const [price, setPrice] = useState(0);
  const [tncCheck, setTncCheck] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(false);

  const disptach = useDispatch();
  const setLoader = useLoader();
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [disablePayBtn, setDisablePayBtn] = useState(false);

  // Coupon states
  const [coupon, setCoupon] = useState("");
  const [couponError, setCouponError] = useState("");
  const [discount, setDiscount] = useState(0);
  const [disableCouponInput, setDisableCouponInput] = useState(false);

  // TnC Modal states
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // useEffect(() => {
  //   disptach(getChallengeOptions());
  // }, [disptach]);

  const totalOrderAmt = useMemo(() => {
    return price - discount;
  }, [price, discount]);

  const { billing_info } = useSelector((state) => state.payment);
  const plans = useSelector((state) => state.payment.pricing.challenges);
  const [accBalanceOptions, setAccBalanceOptions] = useState([]);
  const [couponCode, setCouponCode] = useState("ASDFGVBHN");
  const [couponData, setCouponData] = useState({});
  const [currentCode, setCurrentCode] = useState("");

  const selectedChallenge = useParams();

  const [currentChallenge, setCurrentChallenge] = useState(null);

  let planOptions = plans.map((plan) => ({
    label: plan?.name,
    value: plan?.type,
    id: plan?.type,
  }));

  const [selectedValue, setSelectedValue] = useState(
    planOptions.find((plan) => plan.id === currentChallenge?.id) ||
      planOptions[0]
  );

  useEffect(() => {
    setSelectedPlan(Number(selectedChallenge?.id));
  }, [selectedChallenge]);
  const [data, setdata] = useState({
    email: billing_info?.email,
    full_name: billing_info?.full_name,
    contact: billing_info?.contact,
  });
  const formdata = new FormData();
  formdata.append("email", data.email);
  formdata.append("full_name", data.full_name);
  formdata.append("contact", data.contact);
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${baseUrl}payment/create/hyros/leads/`, formdata)
      .then((res) => console.log(res))
      .catch((error) => console.log(error));

    if (!selectedPlan) {
      disptach(returnErrors("pls-slt-plan-acc-bal", "error"));
      return;
    }
    if (!tncCheck) {
      disptach(returnErrors("accept-tnc", "error"));
      return;
    }

    setLoader(true);
    const payload = { challenge_id: selectedPlan };
    if (coupon) payload["coupon_code"] = coupon;
    payload["payment_method"] = paymentMethod;

    if (paymentMethod === "coinpayments") {
      payload.crypto = coinMethod;
    }

    payload["success_url"] = STRIPE_SUCCESS_URL;
    payload["cancel_url"] = STRIPE_CANCEL_URL;
    if (paymentMethod === "coinbase") {
      disptach(paymentGateway(payload, paymentMethod))
        .then((res) => {
          setLoader(false);
          if (res.url) {
            localStorage.setItem("payMethod", paymentMethod);
            window.location.replace(res.url);
          } else {
            navigate("/overview");
          }
        })
        .catch((error) => setLoader(false));
    }
    if (paymentMethod === "coinpayments") {
      disptach(paymentGateway(payload, paymentMethod))
        .then((res) => {
          setLoader(false);
          if (res.url) {
            localStorage.setItem("payMethod", paymentMethod);
            window.location.replace(res.url);
          } else {
            navigate("/overview");
          }
        })
        .catch((error) => setLoader(false));
    }
    if (paymentMethod === "paystack") {
      disptach(paymentGateway(payload, paymentMethod))
        .then((res) => {
          setLoader(false);
          if (res.url) {
            localStorage.setItem("payMethod", paymentMethod);
            window.location.replace(res.url);
          } else {
            navigate("/overview");
          }
        })
        .catch((error) => setLoader(false));
    } else if (paymentMethod === "stripe") {
      disptach(paymentGateway(payload))
        .then((res) => {
          setLoader(false);
          if (res.url) {
            window.location.replace(res.url);
          } else {
            navigate("/overview");
          }
        })
        .catch((error) => setLoader(false));
    }
  };
  const dispatch = useDispatch();

  const validateCouponCode = (coupon = null) => {
    setDisablePayBtn(true);
    const payload = { challenge: selectedPlan };
    if (coupon) payload.coupon_code = coupon;

    dispatch(applyCouponCode(payload))
      .then((res) => {
        setCouponError("");
        setDisablePayBtn(false);

        setDiscount(res.data.discount);
        if (res.data.disable_input) setDisableCouponInput(true);
        if (res.data.coupon) setCoupon(res.data.coupon);
      })
      .catch((error) => {
        setDiscount(null);
        setDisablePayBtn(false);
        setCouponError(error.message || "Invalid coupon");
      });
  };

  const handlePlanChange = (selectedOption) => {
    setCurrentCode("");
    setCouponData({});
    const selectedPlanId = selectedOption?.id;

    const selectedPlan = plans.find((plan) => plan?.type === selectedPlanId);
    setSelectedValue(planOptions.find((plan) => plan?.id === selectedPlanId));
    setPlanType(selectedPlan?.name);

    const selectedPlanChallenges = selectedPlan ? selectedPlan?.challenges : [];

    setAccBalanceOptions(
      selectedPlanChallenges &&
        selectedPlanChallenges.map((item) => ({
          label: item?.account_balance,
          value: item?.account_balance,
          id: item?.id,
          price: item.price,
        }))
    );
    selectedPlanChallenges.length > 0
      ? setAccBalance({
          label:
            selectedPlanChallenges && selectedPlanChallenges[0].account_balance,
          value: selectedPlanChallenges[0].account_balance,
          id: selectedPlanChallenges[0].id,
          price: selectedPlanChallenges[0]?.price,
        })
      : setAccBalance({});
    setCurrentChallenge(selectedPlanChallenges[0]);
  };

  useEffect(() => {
    const challenges = plans?.flatMap((plan) => plan?.challenges);
    const challenge = challenges?.find(
      (item) => item?.id === Number(selectedChallenge?.id)
    );

    setCurrentChallenge(challenge);
    const defaultSelectedPlan = challenge ? challenge.plan : "";
    const selectedPlan = plans?.find((plan) => plan.id === defaultSelectedPlan);

    const palnType = plans?.filter((item) => {
      return item?.challenges?.some(
        (challenge) => challenge?.id === Number(selectedChallenge?.id)
      );
    });
    setSelectedValue(
      palnType.map((item) => ({
        label: item?.name,
        value: item?.type,
        id: item?.type,
      }))
    );
    setAccBalanceOptions(
      palnType[0]?.challenges?.map((item) => ({
        label: item?.account_balance,
        value: item?.account_balance,
        id: item?.id,
        price: item.price,
      }))
    );
    const defaultAccBalance = challenge ? challenge.account_balance : "";
    setAccBalance(
      {
        label: defaultAccBalance,
        value: defaultAccBalance,
        id: challenge?.id,
        price: challenge?.price,
      } || ""
    );
  }, [selectedChallenge?.id, plans]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      height: 48,
      marginTop: "0px",
      // borderRadius: 32,
      background: "#1b1b3d",
      border: "none",
      boxShadow: "none",
      paddingRight: "20px",
      paddingLeft: "10px",
      color: "#A5B5BF !important",
      fontFamily: "DM Sans , sans-serif",
      fontSize: "14px",
      fontWeight: 500,
      flexBasis: "auto",
      borderBottom: "1px solid #040408",
      "&:hover": {
        borderBottom: "2px solid #040408",
      },
    }),
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        paddingLeft: 20,
        color: isSelected ? "white" : undefined,
        backgroundColor: isSelected ? "#1f1f4c" : undefined,
        border: "1px solid var(--Border, #242529)",
        paddingTop: 10,
        paddingBottom: 10,
        "&:hover": {
          backgroundColor: "#1f1f4c",
        },
      };
    },
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#10103f",
      border: "1px solid var(--Border, #242529)",
    }),

    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#80E0C4",
      flexBasis: "auto",
    }),
  };
  const handleAccBalanceChange = (selectedOption) => {
    setCurrentCode("");
    setCouponData({});

    setAccBalance(selectedOption.value);

    const matchingChallenge = plans
      .flatMap((plan) => plan.challenges)
      .find((challenge) => challenge.id === selectedOption.id);

    if (matchingChallenge) {
      setCurrentChallenge(matchingChallenge);

      setAccBalance({
        label: selectedOption.label,
        value: selectedOption.value,
        id: selectedOption.id,
        price: selectedOption.price,
      });
      setPrice(selectedOption?.value);
      setSelectedPlan(selectedOption?.id);
    } else {
      console.error(
        "No matching challenge found for the selected account balance"
      );
    }
  };
  useEffect(() => {
    setPrice(accBalance?.price);
  }, [accBalance]);

  return (
    <Grid container item lg={4}>
      <Grid item xs={12} sx={summaryStyle} className="account-info">
        <Typography sx={titleStyle} mb={3} component="h3">
          {t("summary")}
        </Typography>
        <label>Funding Account</label>
        <div>
          <Select
            value={selectedValue}
            options={planOptions}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={customStyles}
            onChange={(selectedOption) => handlePlanChange(selectedOption)}
          />
        </div>
        <label>Account Balance</label>
        <div>
          <Select
            value={accBalance}
            options={accBalanceOptions}
            styles={customStyles}
            onChange={(selectedOption) => {
              handleAccBalanceChange(selectedOption);
              setDiscount(null);
              setCoupon("");
            }}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>{" "}
        <FormGroup>
          <FormControl sx={summaryItemCtn}>
            <Typography component={"label"}>{t("provider")}</Typography>
            <Typography component={"h5"}>Mac&Gray</Typography>
          </FormControl>
          <FormControl sx={summaryItemCtn}>
            <Typography component={"label"} style={{ color: "#f26122" }}>
              {t("coupon-code")}
            </Typography>
            <Box component={"div"} display="flex" flexDirection={"column"}>
              <Input
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                sx={{ color: "white", marginTop: 0 }}
                placeholder="Enter Coupon Code"
                onBlur={(e) => {
                  if (e.target.value) {
                    validateCouponCode(coupon);
                  }
                }}
                disabled={disableCouponInput}
                error={couponError ? couponError : false}
              />
              <FormHelperText error={true}>
                {couponError ? couponError : false}
              </FormHelperText>
            </Box>
          </FormControl>
          <FormControl sx={summaryItemCtn}>
            <Typography component={"label"}>{t("platform")}</Typography>
            <Typography component={"h5"}>Mac&Gray</Typography>
          </FormControl>
          <FormControl sx={{ ...summaryItemCtn, margin: "0.5rem 0" }}>
            <Typography component={"label"}>{t("price")}</Typography>
            <Typography component={"h5"}>{dollarUS(price)}</Typography>
          </FormControl>
          <FormControl sx={{ ...summaryItemCtn, margin: "0.5rem 0" }}>
            <Typography component={"label"}>{t("discount")}</Typography>
            <Typography component={"h5"}>{dollarUS(discount)}</Typography>
          </FormControl>
          <FormControl sx={{ ...summaryItemCtn, margin: "0.5rem 0" }}>
            <Typography component={"label"}>{t("order-total")}</Typography>
            <Typography component={"h5"}>{dollarUS(totalOrderAmt)}</Typography>
          </FormControl>
          <Grid
            container
            sx={{
              background: "rgba(255, 255, 255, 0.02)",
              borderRadius: "12px",
              padding: "15px 12px",
              "& .to-be-paid b": {
                color: "#9292AB",
              },
              "& .to-be-paid-label": {
                borderRight: "1px solid",
              },
            }}
          >
            <Grid
              item
              xs={12}
              className="to-be-paid-label"
              sx={{
                borderRight: "1px solid #636363",
                marginRight: "10px",
                flexBasis: "48%",
              }}
            >
              <b>{t("to-be-paid")}</b>
            </Grid>
            <Grid item xs={12} className="to-be-paid">
              <b>{dollarUS(totalOrderAmt)}</b>
            </Grid>
          </Grid>
          <Grid container mt={1.25}>
            <Grid item xs={1.5}>
              <BpCheckbox
                checked={tncCheck}
                onChange={() => setTncCheck(!tncCheck)}
                inputProps={{ "aria-label": "accept terms and conditions" }}
              />
            </Grid>
            <Grid onClick={handleOpen} item xs={10.5} className="tnc-ctn">
              <Typography
                className="tnc-link"
                component={"label"}
                sx={{ flexBasis: "100%" }}
              >
                {t("tnc")}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Button
              onClick={handleSubmit}
              disabled={disablePayBtn}
              className="pay-btn"
              fullWidth
              color="secondary"
              variant="contained"
              type="submit"
            >
              {t("pay")} {dollarUS(totalOrderAmt)}
            </Button>
          </Grid>
        </FormGroup>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("tnc")}
          </Typography>
          <Typography id="tnc_modal" sx={{ mt: 2 }} component={"p"}>
            <TnCContent />
          </Typography>
          <Button
            sx={{ maxWidth: "200px", alignSelf: "center" }}
            onClick={handleClose}
            variant="outlined"
            color={"secondary"}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
};

export default PaymentForm;
