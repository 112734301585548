import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  createSvgIcon,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import coinbaseIcon from "../../../assets/images/coinbaseIcon.png";
import paystackIcon from "../../../assets/images/paystack.png";
import { getBillingInfo } from "../../../store/reducers/payment";
import "../style.scss";
import PaymentForm from "./PaymentForm";

import CoinPayment from "../../../assets/SVG/Coin-payment.svg";
import USDC from "../../../assets/SVG/CoinPaymentSvgs/USDC.svg";
import USDT_ICON from "../../../assets/SVG/CoinPaymentSvgs/USDT_ICON.png";

import ReactGA from "react-ga";
const titleStyle = {
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "140%",
  letterSpacing: "0.004em",
  color: "#FFFFFF",
};

const sectionStyle = {
  borderBottom: "1px solid #464646",
  paddingBottom: "1.5rem",
  paddingTop: "1.5rem",
};

const labelStyle = {
  fontSize: "14px",
  lineHeight: "140%",
  letterSpacing: "0.004em",
  color: "#8F8F8F",
};

const StripeIcon = createSvgIcon(
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4404 0.286133H3.55945C1.7541 0.286133 0.285645 1.75518 0.285645 3.55994V18.4409C0.285645 20.2457 1.7541 21.7147 3.55945 21.7147H18.4404C20.2458 21.7147 21.7142 20.2457 21.7142 18.4409V3.55994C21.7142 1.75518 20.2458 0.286133 18.4404 0.286133ZM10.7023 17.548C9.38743 17.548 8.06303 17.2088 7.03029 16.8516C6.91064 16.8105 6.83326 16.6974 6.83326 16.5707V13.7963C6.83326 13.6897 6.94041 13.6165 7.03803 13.6588C7.73267 13.9576 8.98326 14.429 10.2124 14.5582C10.7761 14.6171 11.3291 14.5552 11.6404 14.2326C11.7487 14.1201 11.8922 13.8909 11.8922 13.5439C11.8922 13.2778 11.8404 12.6546 10.3839 12.226C9.07374 11.8588 6.53564 11.0135 6.53564 8.32185C6.53564 6.53732 7.78267 4.4528 11.2975 4.4528C12.6124 4.4528 13.9368 4.79209 14.9696 5.14923C15.0892 5.1903 15.1666 5.30339 15.1666 5.43018V8.19923C15.1666 8.30637 15.0589 8.37899 14.9612 8.33613C14.2696 8.03375 13.0231 7.55994 11.794 7.45101C11.238 7.38018 10.6333 7.43375 10.338 7.72423C10.2422 7.81828 10.1071 8.00816 10.1071 8.28554C10.1071 8.51351 10.1142 9.02244 11.5886 9.47066C14.1606 10.2213 15.4642 11.5855 15.4642 13.5296C15.4642 15.3838 14.2172 17.548 10.7023 17.548Z"
      fill="#635BFF"
    />
  </svg>
);

// const BitgoIcon = createSvgIcon(<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
// <path d="M9 20C9 20 17.5 17.0931 17.5 6.40322V2.55861C17.5 2.14334 17.1808 1.79504 16.7684 1.76825C15.2254 1.70127 11.5673 1.37977 9 0C6.43271 1.37977 2.77465 1.70127 1.23161 1.76825C0.819249 1.79504 0.5 2.14334 0.5 2.55861V6.40322C0.5 17.0931 9 20 9 20Z" fill="#009FE8"/>
// <path d="M12.4297 7.81032C12.3233 6.64487 11.3389 6.24299 10.0752 6.12243V4.48813H9.09089V6.06885C8.83815 6.06885 8.55881 6.06885 8.29277 6.06885V4.47473H7.30842L7.32173 6.09564C7.10889 6.09564 5.35304 6.10903 5.35304 6.10903V7.14052H6.60342V12.3515H5.49936L5.48606 13.5438C5.48606 13.5438 7.04238 13.5304 7.26852 13.5438V15.1781H8.23956V13.5572C8.5056 13.5706 8.77164 13.5706 9.02438 13.5706V15.1781H10.0087V13.5438C11.6582 13.4634 12.8021 13.0481 12.9618 11.4942C13.0815 10.2484 12.5095 9.67235 11.5784 9.44462C12.1503 9.1767 12.5095 8.66766 12.4297 7.81032ZM11.033 11.2933C11.0197 12.5123 8.95787 12.3649 8.30607 12.3649V10.1948C8.95787 10.1948 11.0463 10.0206 11.033 11.2933ZM10.594 8.23899C10.5807 9.35085 8.86475 9.21689 8.31937 9.2035V7.23429C8.86475 7.23429 10.6073 7.07354 10.594 8.23899Z" fill="white"/>
// </svg>)

const EvaluationPayment = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const { billing_info } = useSelector((state) => state.payment);
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState("stripe");
  const [coinMethod, setCoinMethod] = useState("USDC");

  const { t } = useTranslation();

  const disptach = useDispatch();

  useEffect(() => {
    disptach(getBillingInfo());
  }, [disptach]);

  const handlePayMethod = (value) => {
    setPaymentMethod(value);
  };
  const handleCoinMethod = (value) => {
    setCoinMethod(value);
  };

  // console.log("paymentMethod", paymentMethod);

  return (
    <Grid container spacing={3} className="mainBGcolor">
      <Grid container item lg={8}>
        <Grid item container xs={12} sx={sectionStyle}>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems={"center"}
            pb={2}
          >
            <Typography component={"span"} sx={titleStyle}>
              {t("general-info")}
            </Typography>
            <Button
              className="outlined-icon-btn"
              variant="outlined"
              onClick={() => navigate("/settings")}
              endIcon={<BorderColorOutlinedIcon />}
            >
              {t("edit-profile")}
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ marginBottom: "12px" }}>
            <Typography sx={labelStyle} component={"label"}>
              {t("full-name")}
            </Typography>
            <Typography>{billing_info && billing_info.full_name}</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ marginBottom: "12px" }}>
            <Typography sx={labelStyle} component={"label"}>
              Email
            </Typography>
            <Typography>{billing_info && billing_info.email}</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ marginBottom: "12px" }}>
            <Typography sx={labelStyle} component={"label"}>
              {t("contact-no")}
            </Typography>
            <Typography>
              {billing_info && billing_info.country_code + billing_info.contact}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} sx={sectionStyle}>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems={"center"}
            pb={2}
          >
            <Typography component={"span"} sx={titleStyle}>
              {t("billing-info")}
            </Typography>
            <Button
              className="outlined-icon-btn"
              variant="outlined"
              onClick={() => navigate("/settings")}
              endIcon={<BorderColorOutlinedIcon />}
            >
              {t("change-address")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={labelStyle} component={"label"}>
              {t("billing-address")}
            </Typography>
            <Typography>
              {billing_info && billing_info.city + ", " + billing_info.country}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12} sx={sectionStyle}>
          <Grid
            item
            container
            xs={12}
            justifyContent="start"
            alignItems={"center"}
            pb={2}
          >
            <Typography component={"span"} sx={titleStyle}>
              {t("payment-method")}
            </Typography>
          </Grid>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            display="flex"
            value={paymentMethod}
          >
            <FormControlLabel
              onClick={() => handlePayMethod("stripe")}
              value="stripe"
              control={<Radio color="secondary" />}
              label={
                <Grid container alignItems={"center"} gap={1}>
                  <StripeIcon />
                  <Typography>Stripe</Typography>
                </Grid>
              }
            />
            {/* <FormControlLabel onClick={()=>handlePayMethod('bitgo')} value="bitgo" control={<Radio disabled color="secondary" />} 
              label={<Grid container alignItems={'center'} gap={1}><BitgoIcon/><Typography>Bitgo</Typography></Grid>} 
              /> */}
          </RadioGroup>

          {/* <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            display="flex"
            value={paymentMethod}
          >
            <FormControlLabel
              onClick={() => handlePayMethod("coinbase")}
              value="coinbase"
              control={<Radio color="secondary" />}
              label={
                <Grid container alignItems={"center"} gap={1}>
                  <img src={coinbaseIcon} alt="" />
                  <Typography>Coinbase</Typography>
                </Grid>
              }
            />
         
          </RadioGroup> */}

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            display="flex"
            value={paymentMethod}
          >
            <FormControlLabel
              onClick={() => handlePayMethod("coinpayments")}
              value="coinpayments"
              control={<Radio color="secondary" />}
              label={
                <Grid container alignItems={"center"} gap={1}>
                  <img src={CoinPayment} alt="CoinPayment" />
                  <Typography>Coin Payments</Typography>
                </Grid>
              }
            />

            {/* <FormControlLabel onClick={()=>handlePayMethod('bitgo')} value="bitgo" control={<Radio disabled color="secondary" />} 
              label={<Grid container alignItems={'center'} gap={1}><BitgoIcon/><Typography>Bitgo</Typography></Grid>} 
              /> */}
          </RadioGroup>

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            display="flex"
            value={paymentMethod}
          >
            {/* <FormControlLabel
              onClick={() => handlePayMethod("paystack")}
              value="paystack"
              control={<Radio color="secondary" />}
              label={
                <Grid container alignItems={"center"} gap={1}>
                  <img src={paystackIcon} alt="" />
                  <Typography>Paystack</Typography>
                </Grid>
              }
            /> */}
            {/* <FormControlLabel onClick={()=>handlePayMethod('bitgo')} value="bitgo" control={<Radio disabled color="secondary" />} 
              label={<Grid container alignItems={'center'} gap={1}><BitgoIcon/><Typography>Bitgo</Typography></Grid>} 
              /> */}
          </RadioGroup>
        </Grid>

        {paymentMethod === "coinpayments" && (
          <Grid item container xs={12} sx={sectionStyle}>
            <Grid
              item
              container
              xs={12}
              justifyContent="start"
              alignItems={"center"}
              pb={2}
            >
              <Typography component={"span"} sx={titleStyle}>
                {t("Crypto method")}
              </Typography>
            </Grid>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              display="flex"
              value={coinMethod}
            >
              <FormControlLabel
                onClick={() => handleCoinMethod("USDT.ERC20")}
                value="USDT.ERC20"
                control={<Radio color="secondary" />}
                label={
                  <Grid container alignItems={"center"} gap={1}>
                    <img
                      src={USDT_ICON}
                      alt="USDT_ICON"
                      style={{ width: "20px" }}
                    />
                    <Typography>USDT.ERC20</Typography>
                  </Grid>
                }
              />

              {/* <FormControlLabel onClick={()=>handlePayMethod('bitgo')} value="bitgo" control={<Radio disabled color="secondary" />} 
              label={<Grid container alignItems={'center'} gap={1}><BitgoIcon/><Typography>Bitgo</Typography></Grid>} 
              /> */}
            </RadioGroup>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              display="flex"
              value={coinMethod}
            >
              <FormControlLabel
                onClick={() => handleCoinMethod("USDT.TRC20")}
                value="USDT.TRC20"
                control={<Radio color="secondary" />}
                label={
                  <Grid container alignItems={"center"} gap={1}>
                    <img
                      src={USDT_ICON}
                      alt="USDT_ICON"
                      style={{ width: "20px" }}
                    />
                    <Typography>USDT.TRC20</Typography>
                  </Grid>
                }
              />
            </RadioGroup>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              display="flex"
              value={coinMethod}
            >
              <FormControlLabel
                onClick={() => handleCoinMethod("USDC")}
                value="USDC"
                control={<Radio color="secondary" />}
                label={
                  <Grid container alignItems={"center"} gap={1}>
                    <img src={USDC} alt="USDC" style={{ width: "20px" }} />
                    <Typography>USDC</Typography>
                  </Grid>
                }
              />
            </RadioGroup>

            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              display="flex"
              value={coinMethod}
            >
              <FormControlLabel
                onClick={() => handleCoinMethod("USDC.TRC20")}
                value="USDC.TRC20"
                control={<Radio color="secondary" />}
                label={
                  <Grid container alignItems={"center"} gap={1}>
                    <img src={USDC} alt="USDC" style={{ width: "20px" }} />
                    <Typography>USDC.TRC20</Typography>
                  </Grid>
                }
              />
              {/* <FormControlLabel onClick={()=>handlePayMethod('bitgo')} value="bitgo" control={<Radio disabled color="secondary" />} 
              label={<Grid container alignItems={'center'} gap={1}><BitgoIcon/><Typography>Bitgo</Typography></Grid>} 
              /> */}
            </RadioGroup>
          </Grid>
        )}
      </Grid>
      <PaymentForm paymentMethod={paymentMethod} coinMethod={coinMethod} />
    </Grid>
  );
};

export default EvaluationPayment;
